import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function EcommerceBrand() {
    return (
        <div>
            <span className="font-semibold">Sàn thương mại điện tử</span>
            <div className="grid grid-cols-2 gap-2 mt-2 select-none">
                <Link
                    to={'/shopee'}
                    className="flex justify-between shadow-sm h-24 bg-white py-2 px-1 overflow-hidden items-center rounded-lg hover:drop-shadow-md hover:bg-slate-50"
                >
                    <div className="h-full p-1 flex aspect-square items-center gap-1 flex-col justify-center">
                        <img
                            className="h-full object-cover overflow-hidden"
                            src="/img/brand-logo/logo-shopee-200.png"
                            alt="shopee"
                        />
                        <span className="font-semibold">Shopee</span>
                    </div>
                    <div className="flex flex-col justify-end text-end">
                        <span className="px-1 text-sm">Hoàn tiền</span>
                        <div>
                            <span className="text-xs text-primary-600 bg-primary-50 px-1 py-[2px] rounded-lg font-semibold">
                                <FontAwesomeIcon
                                    className="rotate-45"
                                    icon={faArrowUp}
                                />{' '}
                                40%
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to={'/lazada'}
                    className="flex justify-between shadow-sm h-24 bg-white py-2 px-1 overflow-hidden items-center rounded-lg hover:drop-shadow-md hover:bg-slate-50"
                >
                    <div className="h-full p-1 flex aspect-square items-center gap-1 flex-col justify-center">
                        <img
                            className="h-full object-cover overflow-hidden"
                            src="/img/brand-logo/logo-lazada-200.png"
                            alt="lazada"
                        />
                        <span className="font-semibold">Lazada</span>
                    </div>
                    <div className="flex flex-col justify-end text-end">
                        <span className="px-1 text-sm">Hoàn tiền</span>
                        <div>
                            <span className="text-xs text-primary-600 bg-primary-50 px-1 py-[2px] rounded-lg font-semibold">
                                <FontAwesomeIcon
                                    className="rotate-45"
                                    icon={faArrowUp}
                                />{' '}
                                36%
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to={'tiktok'}
                    className="flex justify-between shadow-sm h-24 bg-white py-2 px-1 overflow-hidden items-center rounded-lg hover:drop-shadow-md hover:bg-slate-50"
                >
                    <div className="h-full p-1 flex aspect-square items-center gap-1 flex-col justify-center">
                        <img
                            className="h-full overflow-hidden"
                            src="/img/brand-logo/logo-tiktok-shop-200.png"
                            alt="TikTok"
                        />
                        <span className="font-semibold">TikTok</span>
                    </div>
                    <div className="flex flex-col justify-end text-end">
                        <span className="px-1 text-sm">Hoàn tiền</span>
                        <div>
                            <span className="text-xs text-primary-600 bg-primary-50 px-1 py-[2px] rounded-lg font-semibold">
                                5-25%
                            </span>
                        </div>
                    </div>
                </Link>
                {/* <div className="flex relative border-red-500 border justify-center shadow-sm h-24 bg-white py-2 px-1 bg-gradient-to-tr from-emerald-100 to-pink-500  items-center rounded-lg hover:drop-shadow-md hover:bg-slate-50">
                    <div className="p-1 flex  items-center gap-1 flex-col justify-center ">
                        <p className="text-sm">Cùng chờ nhé!</p>
                    </div>
                    <div className="absolute top-1 left-[-4px] border-r-transparent w-16 border-[10px] border-red-600">
                        <span className="absolute top-1/2 left-1/2 text-xs w-full -translate-y-1/2 -translate-x-[60%] text-white">
                            Sắp mở
                        </span>
                    </div>
                </div> */}
                <Link
                    to={'https://taglive.site'}
                    target="_blank"
                    className="flex relative border-red-500 border justify-center shadow-sm h-24 bg-white py-2 px-1  items-center rounded-lg hover:drop-shadow-md hover:bg-slate-50"
                >
                    <div className="p-1 flex  items-center gap-1 flex-col justify-center">
                        <img
                            className="overflow-hidden w-14 h-14 object-cover rounded-lg"
                            src="/img/brand-logo/shopeelive.jpeg "
                            alt="shopeevideo-logo"
                        />
                        <span className="text-xs">Shopee Live</span>
                    </div>
                    <div className="absolute top-1 left-[-4px] border-r-transparent w-16 border-[10px] border-red-600">
                        <span className="absolute top-1/2 left-1/2 text-xs w-full -translate-y-1/2 -translate-x-[60%] text-white">
                            New
                        </span>
                    </div>
                    <div className="absolute top-1/2 right-3 -translate-y-1/2 text-red-500 flex justify-center items-center gap-1">
                        <span className="text-sm">Go</span>
                        <FontAwesomeIcon icon={faChevronRight} className="" />
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default EcommerceBrand;
